import { template as template_fa9b721e59a24ae0b8739c9ab342a194 } from "@ember/template-compiler";
const FKText = template_fa9b721e59a24ae0b8739c9ab342a194(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
