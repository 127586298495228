import { template as template_33af38938b6a445ba8838ae4b411fb2f } from "@ember/template-compiler";
const FKControlMenuContainer = template_33af38938b6a445ba8838ae4b411fb2f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
