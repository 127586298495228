import { template as template_190eb21720614408b25cad988c39f4dc } from "@ember/template-compiler";
const SidebarSectionMessage = template_190eb21720614408b25cad988c39f4dc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
