import { template as template_86b4af7f6b3b478a82c9d5ad9c7d6654 } from "@ember/template-compiler";
const WelcomeHeader = template_86b4af7f6b3b478a82c9d5ad9c7d6654(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
